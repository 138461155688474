import React from "react";
import Art from "./art";
import Exercise from "./exercise";
import Gaming from "./gaming";
import Journaling from "./journaling";

export default function Hobbies() {
  return (
    <>
      <Art />
      <Exercise />
      <Gaming />
      <Journaling />
    </>
  );
}
