import React from "react";
import { FaPaintBrush } from "@react-icons/all-files/fa/FaPaintBrush";
import Hobby from "../hobby";

export default function Art() {
  return (
    <Hobby
      icon={<FaPaintBrush />}
      label="Art"
    >
      I dabble in both traditional (pen, pencil, etc.) and digital
      (graphic tablet) art. I usually draw portraits, characters, still lifes,
      and landscapes. If you're interested, you can find samples of my art on my
      {" "}
      <a
        className={ `link` }
        href="https://asitwala.herokuapp.com/gallery/"
        rel="noreferrer noopener"
        target="_blank"
      >
        old website
      </a>
      {" "}
      <i>(please give it some time to load)</i> and
      {" "}
      <a
        className={ `link` }
        href="https://www.instagram.com/awishbonegirl/"
        rel="noreferrer noopener"
        target="_blank"
      >
        Instagram
      </a>!
    </Hobby>
  );
};