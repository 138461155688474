import React from "react";
import { Image } from "react-image-and-background-image-fade";
import { FaGamepad } from "@react-icons/all-files/fa/FaGamepad";
import Hobby from "../hobby";

import styles from "../../../styles/gaming.module.scss";

export default function Exercise() {
  return (
    <Hobby
      icon={<FaGamepad />}
      label="Casual Gaming"
    >
      I like gaming from time to time, and I've also found
      it to be a fun way to bond with family, friends, and even co-workers!
      Some of my favorite games throughout the years have been <b>Horizon Zero Dawn &amp; Forbidden West</b>, <b>Starcraft I &amp; II</b> (
      <Image
      wrapperClassName={`${styles.pylon}`}
      src={ `${process.env.GATSBY_ASSETS_URL}/pylon.png` }
      width="18px"
      height="18px"
      alt="Protoss Pylon"
      lazyLoad
      /> Protoss forever), Frostpunk, Valheim, Palworld, Gears 5, Diablo III &amp; IV, Super Smash Bros, and
      Super Mario Bros.
    </Hobby>
  );
};