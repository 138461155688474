import React from "react";
import { FaLeaf } from "@react-icons/all-files/fa/FaLeaf";
import Hobby from "../hobby";

export default function Exercise() {
  return (
    <Hobby
      icon={<FaLeaf />}
      label="Exercise & Nature"
    >
      Exercising and being outdoors have been
      a lifelong part of my identity. On a weekly basis, I either walk, run, kickbox, or do
      calisthenics. Throughout the year, I waterski, wakeboard, ski, and hike. My
      current favorite hiking spots are <b>Big Pine Lakes</b> in California, <b>Bryce Canyon National
      Park</b> in Utah, <b>Pine Creek Canyon</b> (Red Rock Conservation
      Area) + <b>Mount Charleston</b> in Nevada <i>(as seen in the picture above)</i>!
    </Hobby>
  );
};