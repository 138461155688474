import React from "react";
import { FaListUl } from "@react-icons/all-files/fa/FaListUl";
import Hobby from "../hobby";

export default function Journaling() {
  return (
    <Hobby
      icon={<FaListUl />}
      label="Journaling & Reflection"
    >
      For 6 years, I faced a tough medical condition that led me to visit over 10 doctors and
      have 7 surgeries, with countless additional minor procedures. This period in my life was incredibly
      mentally and physically challenging. I turned to journaling to help me face and reframe
      my thoughts, which were often laced with fear and negativity. These days, I frequently journal on
      <a
      className={ `link` }
      href="https://notion.so"
      rel="noreferrer noopener"
      target="_blank"
      > Notion </a> - I regularly try to write about finding the beauty in every day, prioritizing happiness, 
      living simply, and not borrowing trouble.
    </Hobby>
  );
};