import React from "react";
import { IconContext } from "@react-icons/all-files";
import styles from "../../styles/hobby.module.scss";

export default function Hobby({
  icon,
  label,
  children
}) {
  return (
    <div className={ styles.hobby }>
      <div className={ styles.hobby__icon }>
        <IconContext.Provider value={{ size: '18px', color: '#FF9A23' }}>
          {icon}
        </IconContext.Provider>
      </div>

      <div>
        <div className={ styles.hobby__label }>
          {label}
        </div>
        {children}
      </div>
    </div>
  );
};